<template>
  <div class="Carte">
    <component
      :is="componentFile"
      :texteEnvoye="texte"
      :taille="0.8" />
  </div>
</template>

<script>

import {
  defineComponent,
  ref,
  onMounted,
  computed,
  defineAsyncComponent,
} from 'vue';

import axios from 'axios';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'Carte',
  setup() {
    const route = useRoute();
    const texte = ref('');
    // eslint-disable-next-line
    const componentFile = computed(() => defineAsyncComponent(() => import('@/components/' + route.params.annee + '/Visionneuse.vue')));

    onMounted(async () => {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/textes/${route.params.id}`);
      texte.value = res.data;
    });

    return {
      texte,
      componentFile,
    };
  },
});
</script>
