<template>
  <div class="aPropos">
    <h1>À propos</h1>
    <p>
      Ce générateur de carte a été réalisé par César LIZUREY,
      du Service de la Transformation de la Gendarmerie Nationale.
    </p>
    <p>
      Le code source est disponible sur la plateforme
      <a
        href="https://omnibus-pic.gendarmerie.fr/service-de-la-transformation/carte-de-voeux"
        target="_blank">
        Omnibus</a> (vous devez être sur l'intranet Gendarmerie pour y accéder).
    </p>
    <img :src="`${publicPath}img/logo_ST_couleur.png`" />
  </div>
</template>

<script>
export default {
  name: 'Apropos',
  data: () => ({
    publicPath: process.env.VUE_APP_PUBLICPATH,
  }),
};
</script>
